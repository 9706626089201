// Text colours
$headingColour: #242e2b !default;
$bodyColour: #606666 !default;
$linkColour: #606666 !default;
$hoverColour: #4682B4 !default;
$focusColour: #fa407a !default;
$captionColour: #606666 !default;
$white: #ffffff !default;

// Typography
$bodytype: (
  font-family: '"Merriweather", serif',
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
) !default;

$headingtype: (
  font-family: '"Lato", sans-serif',
  regular: 400,
  bold: 700,
  cap-height: 0.75
) !default;

$monospacetype: (
  font-family: "Menlo, monospace",
  regular: 400,
  cap-height: 0.68
) !default;


.typeset h2 {
    font-size: 2rem;
  }

.feature h2 {
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    opacity: 0.8;
    text-shadow: 2px 2px white;
  }

.content {
    margin-top:75px;
  }

@import "alembic";
